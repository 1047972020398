import { render, staticRenderFns } from "./Spiner.vue?vue&type=template&id=eca645f8&scoped=true&"
import script from "./Spiner.vue?vue&type=script&lang=js&"
export * from "./Spiner.vue?vue&type=script&lang=js&"
import style0 from "./Spiner.vue?vue&type=style&index=0&id=eca645f8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eca645f8",
  null
  
)

export default component.exports